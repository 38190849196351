<template>
  <div class="result flex-column">
    <modal-header-with-previous
      class="result__modal-header"
      current-modal-name="photo-analysis-result-update-modal"
      previous-modal-name="analysis-results-overview-modal"
      is-custom-close-handler
      @close-modal="beforeCloseModal"
    >
      <span slot="header-title" class="header__title">
        {{ $t('dashboard.photoAnalysis.label.stepOne') }}
      </span>
    </modal-header-with-previous>
    <main class="result__content">
      <section class="result__questions flex-column">
        <zoomable-photo v-if="photoUrl" class="zoomable-photo-mobile" :photo="photoUrl" />
        <div ref="age-group-container" class="field-container personal-data-select" style="width:330px;">
          <div class="field-container__title">
            {{ $t('dashboard.photoAnalysis.age') }}
            <i>({{ $t('dashboard.photoAnalysis.label.specify') }})</i>
          </div>
          <q-select
            group-label="ageGroup"
            group-values="ages"
            :group-select="false"
            :options="ageSelectOptions"
            :value="selectFieldsData.ageGroup"
            @input="onFieldChange('ageGroup', $event)"
          />
        </div>
        <div
          v-for="field in personalDataFields"
          :key="field.label"
          class="field-container personal-data-select"
          style="width:330px;"
        >
          <div class="field-container__title">
            {{ field.label }}
          </div>
          <span v-if="field.tooltip" v-tooltip="field.tooltip" class="custom-icon icon-info"></span>
          <q-select
            label="label"
            :allow-empty="false"
            :placeholder="$t('dashboard.diagnosticDetails.selectOption')"
            :value="selectFieldsData[field.propertyName]"
            :options="field.options"
            track-by="label"
            @input="onFieldChange(field.propertyName, $event)"
          />
        </div>
        <zoomable-photo
          v-if="photoUrl"
          class="zoomable-photo-desktop"
          :photo="photoUrl"
          type="big"
        />
      </section>
      <section ref="scores-section" class="result__scores">
      <div v-for="section in sectionsBinding" :key="section.name" class="result__section">
        <slide-down :show="isOpened" class="diagnostic-dropdown">
              <div slot="toggle">
              <div class="flex--space-between--center pointer">
                <div style="width:110px">{{ section.name }}</div>
                <div><diagnostic-dysfunction-slider
                :value="sectionsScores[section.propertyName]"
                :color="section.color"
                @change="updateScore(section.propertyName, $event)"/></div>
                <span
                  class="arrow-icon fas fa-chevron-down"
                  :class="{ 'arrow-icon--opened': isOpened }"
                  style="background-color: #f3f3f8; padding: 7px; border-radius: 5px;"
                ></span>
              </div>
            </div>
             <div
                slot="slide-body"
                class="treatment__content"
                :class="{ 'treatment__content--opened': isOpened }"
              >
              <section class="section__questions" style="align-items: flex-start">
              <div
                v-for="question in section.questions"
                :key="question.label"
                class="field-container question__field"
              >
                <div class="question__label"  style="font-weight: bold">
                  {{ question.label }}
                <v-select
                  label="label"
                  :allow-empty="false"
                  :placeholder="$t('dashboard.medicalData.selectOption')"
                  :value="selectFieldsData[question.propertyName]"
                  :options="question.options"
                  track-by="label"
                  class="style-chooser"
                  append-to-body
                  @input="onFieldChange(question.propertyName, $event)"
                />
              </div>
              </div>
            </section>
            </div>
        </slide-down>
       </div>
      </section>
    </main>

    <mobile-fixed-footer class="result__footer flex--center--center">
      <button slot="footer" class="rounded-button-white" @click="onNextClick">
        {{ $t('action.next') }}
      </button>
    </mobile-fixed-footer>

    <quit-photo-analysis-warning-modal class="onlyXS" return-to-opened-patient />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import { get } from 'lodash';
import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import QSelect from '@/modules/dashboard/components/common/Select';
import VSelect from '@/modules/dashboard/components/common/VSelect';
import SlideDown from '@/modules/dashboard/components/common/SlideDown';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';
import ZoomablePhoto from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/ZoomablePhoto';
import QuitPhotoAnalysisWarningModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/QuitPhotoAnalysisWarningModal';

import SelectDataBinding from '@/components/common/SelectDataBinding';
import PhotoAnalysisResultSection from '@/components/common/PhotoAnalysisResultSection';
import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';

import { fetchPatientPhotoByPhotoId } from '@/modules/dashboard/api';
import DiagnosticDysfunctionSlider from '@/modules/dashboard/components/common/DiagnosticDysfunctionSlider';

import {
  buildImageUrl,
  getMedicalDataSelectOptionsWithLabel,
  getSelectOptionsWithLabel,
  handleRoutingInfo,
  isMobileDevice
} from '@/modules/dashboard/utils';

import { GENDER_VALUES } from '@/modules/questionnaire/api/constants';

import {
  ACTIVES,
  DYSFUNCTIONS_COLORS,
  PERSON_DATA_FIELDS_DEFAULT_VALUES,
  SECTION_DATA_FIELDS_DEFAULT_VALUES,
  PERSON_DATA_FIELDS_NAMES,
  SELECT_DATA_FIELDS_NAMES
  // SELECT_DATA_FIELDS
} from '@/modules/dashboard/api/constants';

import {
  PHOTOTYPE,
  SKIN_THICKNESS,
  YES_NO,
  NO_MODERATE_SEVERE,
  NO_MILD_MODERATE_SEVERE
} from '@/modules/dashboard/api/medicalData';

import { types } from '@/modules/dashboard/store/types';

const SELECT_OPTIONS_FIELDS = {
  genderSelectOptions: null,
  ageGroupOptions: null,
  phototypeSelectOptions: null,
  skinThinknessSelectOptions: null,
  yesNoSelectOptions: null,
  noModerateSevereSelectOptions: null,
  noMildModerateSevereSelectOptions: null,
  yComplexionSelectOptions: null,
  yTirednessSelectOptions: null,
  ySunDamageOptions: null,
  pWrinklesOptions: null,
  pFineLineseOptions: null,
  pElasticitySaggingOptions: null,
  pVolumeeOptions: null,
  rRednessPresentOptions: null,
  rCouperosePresentOptions: null,
  rIsRosaceaOptions: null,
  rIsSunburnOptions: null,
  rIsContactDermatitisOptions: null,
  rIsEczemaOptions: null,
  rIsPsoriasisOptions: null,
  rIsInfectionsOptions: null,
  rIsAcneOptions: null,
  rIsAllergicReactionOptions: null,
  bObservedDrynessOptions: null,
  bObservedDehydrationOptions: null,
  bPredictiveFactorsDrynessOptions: null,
  bPredictiveFactorsDehydrationOptions: null,
  oOilinessOptions: null,
  oPoresOptions: null,
  greyRoughBumpySkinOptions: null,
  greyDullSkinOptions: null,
  greyUnevenSkinTextureOptions: null,
  greyRoughnessOptions: null,
  greyScarringOptions: null,
  greenComedonesOptions: null,
  greenPustulesOptions: null,
  greenPapulesOptions: null,
  greenNodulesOptions: null,
  greenCystsOptions: null,
  brownMelasmaOptions: null,
  brownPostInflammatoryHyperpigmentationOptions: null,
  brownAgeSunSpotsOptions: null,
  brownFrecklesOptions: null,
  brownMolesOptions: null,
  brownSkinToneOptions: null,
  brownPredictiveFactorsHyperpigmentationOptions: null
};

const PHOTO_ANALYSIS_SELECT_OPTIONS_BINDING = [
  { propertyName: 'genderSelectOptions', options: GENDER_VALUES, keyPrefix: 'dashboard.gender.' },
  {
    propertyName: 'skinThinknessSelectOptions',
    options: SKIN_THICKNESS,
    keyPrefix: 'dashboard.photoAnalysis.'
  }
];

const OPT_COMPLEXION = ['complexion1', 'complexion2', 'complexion3', 'complexion4'];
const OPT_TIREDNESS = ['tiredness1', 'tiredness2', 'tiredness3', 'tiredness4'];
const OPT_SUNDAMAGE = ['sun_damage1', 'sun_damage2', 'sun_damage3', 'sun_damage4'];
const OPT_WRINKLESE = ['wrinkles1', 'wrinkles2', 'wrinkles3', 'wrinkles4'];
const OPT_FINELINESE = ['fine_lines1', 'fine_lines2', 'fine_lines3', 'fine_lines4'];
const OPT_ELASTICITYSAGGING = ['sagging1', 'sagging2', 'sagging3', 'sagging4'];
const OPT_VOLUME = ['volume1', 'volume2', 'volume3', 'volume4'];
const OPT_REDNESSPRESENT = ['redness1', 'redness2', 'redness3', 'redness4', 'redness5'];
const OPT_COUPEROSEPRESENT = ['couperose1', 'couperose2', 'couperose3', 'couperose4', 'couperose5'];
const OPT_ISROSACEA = ['rosacea1', 'rosacea2', 'rosacea3'];
const OPT_ISSUNBURN = ['sunburn1', 'sunburn2', 'sunburn3'];
const OPT_ISCONTACTDERMATITIS = ['contact_dermatitis1', 'contact_dermatitis2', 'contact_dermatitis3'];
const OPT_ISECZEMA = ['eczema1', 'eczema2', 'eczema3'];
const OPT_ISPSORIASIS = ['psoriasis1', 'psoriasis2', 'psoriasis3'];
const OPT_ISINFECTIONS = ['infections1', 'infections2', 'infections3'];
const OPT_ISACNE = ['acne1', 'acne2', 'acne3'];
const OPT_ISALLERGICREACTION = ['allergic_reaction1', 'allergic_reaction2', 'allergic_reaction3'];

const OPT_OBSERVEDDRYNESS = ['dryness1', 'dryness2', 'dryness3', 'dryness4'];
const OPT_OBSERVEDDEHYDRATION = ['dehydration1', 'dehydration2', 'dehydration3', 'dehydration4'];
const OPT_PREDICTIVEFACTORSDRYNESS = ['predictive_factors_dryness1', 'predictive_factors_dryness2', 'predictive_factors_dryness3', 'predictive_factors_dryness4'];
const OPT_PREDICTIVEFACTORSDEHYDRATION = ['predictive_factors_dehydration1', 'predictive_factors_dehydration2', 'predictive_factors_dehydration3', 'predictive_factors_dehydration4'];
const OPT_OILINESS = ['oiliness1', 'oiliness2', 'oiliness3', 'oiliness4'];
const OPT_PORES = ['pores1', 'pores2', 'pores3', 'pores4'];
const OPT_ROUGHBUMPYSKIN = ['rough_bumpy_skin1', 'rough_bumpy_skin2', 'rough_bumpy_skin3', 'rough_bumpy_skin4'];
const OPT_DULLSKIN = ['dull_skin1', 'dull_skin2', 'dull_skin3', 'dull_skin4'];
const OPT_UNEVENSKINTEXTURE = ['uneven_skin_texture1', 'uneven_skin_texture2', 'uneven_skin_texture3', 'uneven_skin_texture4'];
const OPT_ROUGHNESS = ['roughness1', 'roughness2', 'roughness3', 'roughness4'];

const OPT_SCARRING = ['scarring1', 'scarring2', 'scarring3'];
const OPT_COMEDONES = ['comedones1', 'comedones2', 'comedones3', 'comedones4'];
const OPT_PUSTULES = ['pustules1', 'pustules2', 'pustules3', 'pustules4'];
const OPT_PAPULES = ['papules1', 'papules2', 'papules3', 'papules4'];
const OPT_NODULES = ['nodules1', 'nodules2', 'nodules3', 'nodules4'];
const OPT_CYSTS = ['cysts1', 'cysts2', 'cysts3', 'cysts4'];
const OPT_MELASMA = ['melasma1', 'melasma2', 'melasma3', 'melasma4'];
const OPT_POSTINFLAMMATORYHYPERPIGMENTATION = ['pih1', 'pih2', 'pih3', 'pih4'];
const OPT_AGESUNSPOTS = ['age_sun_spots1', 'age_sun_spots2', 'age_sun_spots3', 'age_sun_spots4'];

const OPT_FRECKLES = ['freckles1', 'freckles2', 'freckles3'];
const OPT_MOLES = ['moles1', 'moles2', 'moles3'];
const OPT_SKINTONE = ['skin_tone1', 'skin_tone2', 'skin_tone3', 'skin_tone4'];
const OPT_PREDICTIVEFACTORSHYPERPIGMENTATION = ['predictive_factors_hyperpigmentation1', 'predictive_factors_hyperpigmentation2', 'predictive_factors_hyperpigmentation3', 'predictive_factors_hyperpigmentation4'];

const MEDICAL_DATA_SELECT_OPTIONS_BINDING = [
  { propertyName: 'phototypeSelectOptions', options: PHOTOTYPE },
  { propertyName: 'yesNoSelectOptions', options: YES_NO },
  { propertyName: 'noModerateSevereSelectOptions', options: NO_MODERATE_SEVERE },
  { propertyName: 'noMildModerateSevereSelectOptions', options: NO_MILD_MODERATE_SEVERE },
  { propertyName: 'yComplexionSelectOptions', options: OPT_COMPLEXION },
  { propertyName: 'yTirednessSelectOptions', options: OPT_TIREDNESS },
  { propertyName: 'ySunDamageOptions', options: OPT_SUNDAMAGE },
  { propertyName: 'pWrinklesOptions', options: OPT_WRINKLESE },
  { propertyName: 'pFineLineseOptions', options: OPT_FINELINESE },
  { propertyName: 'pElasticitySaggingOptions', options: OPT_ELASTICITYSAGGING },
  { propertyName: 'pVolumeeOptions', options: OPT_VOLUME },
  { propertyName: 'rRednessPresentOptions', options: OPT_REDNESSPRESENT },
  { propertyName: 'rCouperosePresentOptions', options: OPT_COUPEROSEPRESENT },
  { propertyName: 'rIsRosaceaOptions', options: OPT_ISROSACEA },
  { propertyName: 'rIsSunburnOptions', options: OPT_ISSUNBURN },
  { propertyName: 'rIsContactDermatitisOptions', options: OPT_ISCONTACTDERMATITIS },
  { propertyName: 'rIsEczemaOptions', options: OPT_ISECZEMA },
  { propertyName: 'rIsPsoriasisOptions', options: OPT_ISPSORIASIS },
  { propertyName: 'rIsInfectionsOptions', options: OPT_ISINFECTIONS },
  { propertyName: 'rIsAcneOptions', options: OPT_ISACNE },
  { propertyName: 'rIsAllergicReactionOptions', options: OPT_ISALLERGICREACTION },
  { propertyName: 'bObservedDrynessOptions', options: OPT_OBSERVEDDRYNESS },
  { propertyName: 'bObservedDehydrationOptions', options: OPT_OBSERVEDDEHYDRATION },
  { propertyName: 'bPredictiveFactorsDrynessOptions', options: OPT_PREDICTIVEFACTORSDRYNESS },
  { propertyName: 'bPredictiveFactorsDehydrationOptions', options: OPT_PREDICTIVEFACTORSDEHYDRATION },
  { propertyName: 'oOilinessOptions', options: OPT_OILINESS },
  { propertyName: 'oPoresOptions', options: OPT_PORES },
  { propertyName: 'greyRoughBumpySkinOptions', options: OPT_ROUGHBUMPYSKIN },
  { propertyName: 'greyDullSkinOptions', options: OPT_DULLSKIN },
  { propertyName: 'greyUnevenSkinTextureOptions', options: OPT_UNEVENSKINTEXTURE },
  { propertyName: 'greyRoughnessOptions', options: OPT_ROUGHNESS },
  { propertyName: 'greyScarringOptions', options: OPT_SCARRING },
  { propertyName: 'greenComedonesOptions', options: OPT_COMEDONES },
  { propertyName: 'greenPustulesOptions', options: OPT_PUSTULES },
  { propertyName: 'greenPapulesOptions', options: OPT_PAPULES },
  { propertyName: 'greenNodulesOptions', options: OPT_NODULES },
  { propertyName: 'greenCystsOptions', options: OPT_CYSTS },
  { propertyName: 'brownMelasmaOptions', options: OPT_MELASMA },
  { propertyName: 'brownPostInflammatoryHyperpigmentationOptions', options: OPT_POSTINFLAMMATORYHYPERPIGMENTATION },
  { propertyName: 'brownAgeSunSpotsOptions', options: OPT_AGESUNSPOTS },
  { propertyName: 'brownFrecklesOptions', options: OPT_FRECKLES },
  { propertyName: 'brownMolesOptions', options: OPT_MOLES },
  { propertyName: 'brownSkinToneOptions', options: OPT_SKINTONE },
  { propertyName: 'brownPredictiveFactorsHyperpigmentationOptions', options: OPT_PREDICTIVEFACTORSHYPERPIGMENTATION }
];

const SECTIONS_SCORE_DEFAULT_VALUES = {
  yellow: 0,
  pink: 0,
  red: 0,
  blue: 0,
  orange: 0,
  grey: 0,
  green: 0,
  brown: 0
};

const SECTIONS_SCORES_FIELDS = Object.keys(SECTIONS_SCORE_DEFAULT_VALUES);

const CLARIFICATION_ROUNTING_INFO = new RoutingInfo(
  'clarification-modal',
  'PhotoAnalysisClarification'
);

const FIELD_TO_OPTIONS_PROPERTY_BINDING = {
  gender: 'genderSelectOptions',
  phototype: 'phototypeSelectOptions',
  skinThickness: 'skinThinknessSelectOptions',
  hasAcneScars: 'yesNoSelectOptions',
  wrinklesDepth: 'noModerateSevereSelectOptions',
  hasRosacea1: 'yesNoSelectOptions',
  hasCouperose: 'yesNoSelectOptions',
  dryness: 'noMildModerateSevereSelectOptions',
  dehydration: 'noMildModerateSevereSelectOptions',
  hasAcneVulgaris: 'yesNoSelectOptions',
  hasAcneRosacea: 'yesNoSelectOptions',
  hasAcneNodular: 'yesNoSelectOptions',
  comesFromPIH: 'yesNoSelectOptions',
  comesFromAgeSpotsMelasma: 'yesNoSelectOptions',
  skinSensitivity: 'yesNoSelectOptions',
  complexion: 'yComplexionSelectOptions',
  tiredness: 'yTirednessSelectOptions',
  sunDamage: 'ySunDamageOptions',
  wrinkles: 'pWrinklesOptions',
  fineLines: 'pFineLineseOptions',
  elasticitySagging: 'pElasticitySaggingOptions',
  volume: 'pVolumeeOptions',
  rednessPresent: 'rRednessPresentOptions',
  couperosePresent: 'rCouperosePresentOptions',
  isRosacea: 'rIsRosaceaOptions',
  isSunburn: 'rIsSunburnOptions',
  isContactDermatitis: 'rIsContactDermatitisOptions',
  isEczema: 'rIsEczemaOptions',
  isPsoriasis: 'rIsPsoriasisOptions',
  isInfections: 'rIsInfectionsOptions',
  isAcne: 'rIsAcneOptions',
  isAllergicReaction: 'rIsAllergicReactionOptions',
  observedDryness: 'bObservedDrynessOptions',
  observedDehydration: 'bObservedDehydrationOptions',
  predictiveFactorsDryness: 'bPredictiveFactorsDrynessOptions',
  predictiveFactorsDehydration: 'bPredictiveFactorsDehydrationOptions',
  oiliness: 'oOilinessOptions',
  pores: 'oPoresOptions',
  roughBumpySkin: 'greyRoughBumpySkinOptions',
  dullSkin: 'greyDullSkinOptions',
  unevenSkinTexture: 'greyUnevenSkinTextureOptions',
  roughness: 'greyRoughnessOptions',
  scarring: 'greyScarringOptions',
  comedones: 'greenComedonesOptions',
  pustules: 'greenPustulesOptions',
  papules: 'greenPapulesOptions',
  nodules: 'greenNodulesOptions',
  cysts: 'greenCystsOptions',
  melasma: 'brownMelasmaOptions',
  postInflammatoryHyperpigmentation: 'brownPostInflammatoryHyperpigmentationOptions',
  ageSunSpots: 'brownAgeSunSpotsOptions',
  freckles: 'brownFrecklesOptions',
  moles: 'brownMolesOptions',
  skinTone: 'brownSkinToneOptions',
  predictiveFactorsHyperpigmentation: 'brownPredictiveFactorsHyperpigmentationOptions'
};

export const AGE_GROUPS = [
  {
    ageGroup: 'young',
    // Ages from 12 to 19.
    ages: [...Array.from(Array(8)).keys()].map(key => key + 12)
  },
  {
    ageGroup: 'youngAdult',
    // Ages from 20 to 29.
    ages: [...Array.from(Array(10)).keys()].map(key => key + 20)
  },
  {
    ageGroup: 'adult',
    // Ages from 30 to 49.
    ages: [...Array.from(Array(20)).keys()].map(key => key + 30)
  },
  {
    ageGroup: 'senior',
    // Ages from 50 to 120.
    ages: [...Array.from(Array(71)).keys()].map(key => key + 50)
  }
];

const TOOLTIP_CONFIG = {
  sensitiveSkin: {
    content: 'dashboard.dysfunction.sensitiveSkin.hint'
  }
};

export default {
  name: 'PhotoAnalysisResultUpdate',
  components: {
    QuitPhotoAnalysisWarningModal,
    ZoomablePhoto,
    ModalHeaderWithPrevious,
    QSelect,
    VSelect,
    MobileFixedFooter,
    SlideDown,
    DiagnosticDysfunctionSlider
  },
  data() {
    return {
      // scrollbar: null,
      ageSelectOptions: [],
      personalDataFields: [],
      selectFieldsBinding: [],
      sectionsBinding: [],
      selectOptions: SELECT_OPTIONS_FIELDS,
      selectFieldsData: {
        ...PERSON_DATA_FIELDS_DEFAULT_VALUES,
        ...SECTION_DATA_FIELDS_DEFAULT_VALUES,
        ageGroup: null,
        ethnicity: null
      },
      sectionsScores: {
        ...SECTIONS_SCORE_DEFAULT_VALUES
      },
      ageGroupScrollbar: null,
      scoreSectionScrollbar: null,
      photoUrl: null,
      isOpened: false
    };
  },
  computed: {
    ...mapGetters({
      currentPatientId: types.getters.CURRENT_PATIENT_ID,
      photoAnalysisPhotoId: types.getters.PHOTO_ANALYSIS_PHOTO_ID,
      initialUpdatedResults: types.getters.PHOTO_ANALYSIS_UPDATED_RESULTS
    })
  },
  async mounted() {
    if (!isMobileDevice()) {
      const ageGroupRef = this.$refs['age-group-container'].querySelector(
        '.multiselect__content-wrapper'
      );


      const scoreSectionRef = this.$refs['scores-section'];


      const scrollbarOptions = {
        suppressScrollX: true,
        wheelPropagation: false
      };

      this.ageGroupScrollbar = new PerfectScrollbar(ageGroupRef, scrollbarOptions);
      this.scoreSectionScrollbar = new PerfectScrollbar(scoreSectionRef, scrollbarOptions);
    }

    this.createSelectOptions();
    this.selectFieldsBinding = this.getSelectFieldsBinding();
    this.sectionsBinding = this.getSectionsBinding();
    this.setInitialResultData();
    this.personalDataFields = this.getPersonalDataFields();
    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(this.personalDataFields, null, 4));

    const { data: photo } = await fetchPatientPhotoByPhotoId(
      this.currentPatientId,
      this.photoAnalysisPhotoId
    );

    this.photoUrl = buildImageUrl(photo);
  },
  methods: {
    ...mapActions({
      updatePhotoAnalysisResults: types.actions.SET_PHOTO_ANALYSIS_UPDATED_RESULTS
    }),
    beforeCloseModal() {
      this.$modal.show('quit-photo-analysis-warning-modal', {
        noAnswerHandler: () => {
          this.$modal.show('photo-analysis-result-update-modal');
        }
      });

      this.$modal.hide('photo-analysis-result-update-modal');
    },
    setInitialResultData() {
      // alert(JSON.stringify(this.initialUpdatedResults, null, 4));
      const { ethnicity, ageGroup } = this.initialUpdatedResults.characteristics;
      const ageGroupValue = Number.isInteger(ageGroup)
        ? ageGroup
        : this.$t(`dashboard.photoAnalysis.label.${this.initialUpdatedResults.characteristics.ageGroup}`);

      this.selectFieldsData.ethnicity = ethnicity;
      this.selectFieldsData.ageGroup = ageGroupValue;
      PERSON_DATA_FIELDS_NAMES.forEach(fieldName => {
        // alert(fieldName);
        const initialValue = get(this.initialUpdatedResults, ['characteristics', fieldName], null);
        // alert(initialValue);
        const optionsPropertyName = FIELD_TO_OPTIONS_PROPERTY_BINDING[fieldName];

        this.selectFieldsData[fieldName] =
          this.selectOptions[optionsPropertyName].find(({ value }) => value === initialValue) ||
          null;
      });
      // pentru fiecare culoare din culori
      // ANALYSIS_RESULT_COLORS
      // ANALYSIS_RESULT_COLORS.forEach(color =>
      SELECT_DATA_FIELDS_NAMES.forEach(fieldName => {
        // alert(fieldName);
        const initialValue = get(this.initialUpdatedResults, ['yellow', fieldName, 'description'], null);
        // alert(initialValue);
        const optionsPropertyName = FIELD_TO_OPTIONS_PROPERTY_BINDING[fieldName];

        this.selectFieldsData[fieldName] =
          this.selectOptions[optionsPropertyName].find(({ value }) => value === initialValue) ||
          null;
      });
      SECTIONS_SCORES_FIELDS.forEach(scoreField => {
        // alert(JSON.stringify(this.initialUpdatedResults[scoreField], null, 4));
        this.sectionsScores[scoreField] = get(this.initialUpdatedResults, [scoreField, 'dysfunctionScore'], null);
      });
    },
    getPersonalDataFields() {
      return PERSON_DATA_FIELDS_NAMES.map(field => ({
        ...this.selectFieldsBinding[field],
        tooltip: this.$t(get(TOOLTIP_CONFIG, [field, 'content']))
      }));
    },
    createSelectOptions() {
      this.ageSelectOptions = AGE_GROUPS.map(({ ageGroup, ages }) => ({
        ageGroup: this.$t(`dashboard.photoAnalysis.label.${ageGroup}`),
        ages
      }));

      PHOTO_ANALYSIS_SELECT_OPTIONS_BINDING.forEach(({ propertyName, options, keyPrefix }) => {
        this.selectOptions[propertyName] = getSelectOptionsWithLabel({
          $t: this.$t,
          options,
          keyPrefix
        });
      });

      MEDICAL_DATA_SELECT_OPTIONS_BINDING.forEach(binding => {
        this.selectOptions[binding.propertyName] = getMedicalDataSelectOptionsWithLabel(
          this.$t,
          binding.options
        );
      });
    },
    getSelectFieldsBinding() {
      const {
        genderSelectOptions,
        phototypeSelectOptions,
        skinThinknessSelectOptions,
        yesNoSelectOptions,
        noModerateSevereSelectOptions,
        noMildModerateSevereSelectOptions,
        yComplexionSelectOptions,
        yTirednessSelectOptions,
        ySunDamageOptions,
        pWrinklesOptions,
        pFineLineseOptions,
        pElasticitySaggingOptions,
        pVolumeeOptions,
        rRednessPresentOptions,
        rCouperosePresentOptions,
        rIsRosaceaOptions,
        rIsSunburnOptions,
        rIsContactDermatitisOptions,
        rIsEczemaOptions,
        rIsPsoriasisOptions,
        rIsInfectionsOptions,
        rIsAcneOptions,
        rIsAllergicReactionOptions,
        bObservedDrynessOptions,
        bObservedDehydrationOptions,
        bPredictiveFactorsDrynessOptions,
        bPredictiveFactorsDehydrationOptions,
        oOilinessOptions,
        oPoresOptions,
        greyRoughBumpySkinOptions,
        greyDullSkinOptions,
        greyUnevenSkinTextureOptions,
        greyRoughnessOptions,
        greyScarringOptions,
        greenComedonesOptions,
        greenPustulesOptions,
        greenPapulesOptions,
        greenNodulesOptions,
        greenCystsOptions,
        brownMelasmaOptions,
        brownPostInflammatoryHyperpigmentationOptions,
        brownAgeSunSpotsOptions,
        brownFrecklesOptions,
        brownMolesOptions,
        brownSkinToneOptions,
        brownPredictiveFactorsHyperpigmentationOptions
      } = this.selectOptions;

      return {
        gender: new SelectDataBinding(
          this.$t('dashboard.patientCard.gender'),
          'gender',
          genderSelectOptions
        ),
        phototype: new SelectDataBinding(
          this.$t('dashboard.medicalData.phototype'),
          'phototype',
          phototypeSelectOptions
        ),
        skinThickness: new SelectDataBinding(
          this.$t('dashboard.medicalData.skinThickness'),
          'skinThickness',
          skinThinknessSelectOptions
        ),
        hasAcneScars: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.acneScars'),
          'hasAcneScars',
          yesNoSelectOptions
        ),
        wrinklesDepth: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.wrinkles'),
          'wrinklesDepth',
          noModerateSevereSelectOptions
        ),
        hasRosacea1: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.rosacea'),
          'hasRosacea1',
          yesNoSelectOptions
        ),
        hasCouperose: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.couperose'),
          'hasCouperose',
          yesNoSelectOptions
        ),
        dryness: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.dryness'),
          'dryness',
          noMildModerateSevereSelectOptions
        ),
        dehydration: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.dehydration'),
          'dehydration',
          noMildModerateSevereSelectOptions
        ),
        hasAcneVulgaris: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.acneVulgaris'),
          'hasAcneVulgaris',
          yesNoSelectOptions
        ),
        hasAcneRosacea: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.acneRosacea'),
          'hasAcneRosacea',
          yesNoSelectOptions
        ),
        hasAcneNodular: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.acneNodular'),
          'hasAcneNodular',
          yesNoSelectOptions
        ),
        comesFromPIH: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.pih'),
          'comesFromPIH',
          yesNoSelectOptions
        ),
        comesFromAgeSpotsMelasma: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.ageSpot'),
          'comesFromAgeSpotsMelasma',
          yesNoSelectOptions
        ),
        skinSensitivity: new SelectDataBinding(
          this.$t('dashboard.dysfunction.sensitiveSkin.title'),
          'skinSensitivity',
          yesNoSelectOptions
        ),
        complexion: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.complexion'),
          'complexion',
          yComplexionSelectOptions
        ),
        tiredness: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.tiredness'),
          'tiredness',
          yTirednessSelectOptions
        ),
        sunDamage: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.sunDamage'),
          'sunDamage',
          ySunDamageOptions
        ),
        wrinkles: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.wrinkles'),
          'wrinkles',
          pWrinklesOptions
        ),
        fineLines: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.fineLines'),
          'fineLines',
          pFineLineseOptions
        ),
        elasticitySagging: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.elasticitySagging'),
          'elasticitySagging',
          pElasticitySaggingOptions
        ),
        volume: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.volume'),
          'volume',
          pVolumeeOptions
        ),
        rednessPresent: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.rednessPresent'),
          'rednessPresent',
          rRednessPresentOptions
        ),
        couperosePresent: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.couperosePresent'),
          'couperosePresent',
          rCouperosePresentOptions
        ),
        isRosacea: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.isRosacea'),
          'isRosacea',
          rIsRosaceaOptions
        ),
        isSunburn: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.isSunburn'),
          'isSunburn',
          rIsSunburnOptions
        ),
        isContactDermatitis: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.isContactDermatitis'),
          'isContactDermatitis',
          rIsContactDermatitisOptions
        ),
        isEczema: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.isEczema'),
          'isEczema',
          rIsEczemaOptions
        ),
        isPsoriasis: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.isPsoriasis'),
          'isPsoriasis',
          rIsPsoriasisOptions
        ),
        isInfections: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.isInfections'),
          'isInfections',
          rIsInfectionsOptions
        ),
        isAcne: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.isAcne'),
          'isAcne',
          rIsAcneOptions
        ),
        isAllergicReaction: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.isAllergicReaction'),
          'isAllergicReaction',
          rIsAllergicReactionOptions
        ),
        observedDryness: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.observedDryness'),
          'observedDryness',
          bObservedDrynessOptions
        ),
        observedDehydration: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.observedDehydration'),
          'observedDehydration',
          bObservedDehydrationOptions
        ),
        predictiveFactorsDryness: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.predictiveFactorsDryness'),
          'predictiveFactorsDryness',
          bPredictiveFactorsDrynessOptions
        ),
        predictiveFactorsDehydration: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.predictiveFactorsDehydration'),
          'predictiveFactorsDehydration',
          bPredictiveFactorsDehydrationOptions
        ),
        oiliness: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.oiliness'),
          'oiliness',
          oOilinessOptions
        ),
        pores: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.pores'),
          'pores',
          oPoresOptions
        ),
        roughBumpySkin: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.roughBumpySkin'),
          'roughBumpySkin',
          greyRoughBumpySkinOptions
        ),
        dullSkin: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.dullSkin'),
          'dullSkin',
          greyDullSkinOptions
        ),
        unevenSkinTexture: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.unevenSkinTexture'),
          'unevenSkinTexture',
          greyUnevenSkinTextureOptions
        ),
        roughness: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.roughness'),
          'roughness',
          greyRoughnessOptions
        ),
        scarring: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.scarring'),
          'scarring',
          greyScarringOptions
        ),
        comedones: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.comedones'),
          'comedones',
          greenComedonesOptions
        ),
        pustules: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.pustules'),
          'pustules',
          greenPustulesOptions
        ),
        papules: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.papules'),
          'papules',
          greenPapulesOptions
        ),
        nodules: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.nodules'),
          'nodules',
          greenNodulesOptions
        ),
        cysts: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.cysts'),
          'cysts',
          greenCystsOptions
        ),
        melasma: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.melasma'),
          'melasma',
          brownMelasmaOptions
        ),
        postInflammatoryHyperpigmentation: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.postInflammatoryHyperpigmentation'),
          'postInflammatoryHyperpigmentation',
          brownPostInflammatoryHyperpigmentationOptions
        ),
        ageSunSpots: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.ageSunSpots'),
          'ageSunSpots',
          brownAgeSunSpotsOptions
        ),
        freckles: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.freckles'),
          'freckles',
          brownFrecklesOptions
        ),
        moles: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.moles'),
          'moles',
          brownMolesOptions
        ),
        skinTone: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.skinTone'),
          'skinTone',
          brownSkinToneOptions
        ),
        predictiveFactorsHyperpigmentation: new SelectDataBinding(
          this.$t('dashboard.photoAnalysis.label.predictiveFactorsHyperpigmentation'),
          'predictiveFactorsHyperpigmentation',
          brownPredictiveFactorsHyperpigmentationOptions
        )
      };
    },
    getSectionsBinding() {
      const {
        complexion,
        tiredness,
        sunDamage,
        wrinkles,
        fineLines,
        elasticitySagging,
        volume,
        rednessPresent,
        couperosePresent,
        isRosacea,
        isSunburn,
        isContactDermatitis,
        isEczema,
        isPsoriasis,
        isInfections,
        isAcne,
        isAllergicReaction,
        observedDryness,
        observedDehydration,
        predictiveFactorsDryness,
        predictiveFactorsDehydration,
        oiliness,
        pores,
        roughBumpySkin,
        dullSkin,
        unevenSkinTexture,
        roughness,
        scarring,
        comedones,
        pustules,
        papules,
        nodules,
        cysts,
        melasma,
        postInflammatoryHyperpigmentation,
        ageSunSpots,
        freckles,
        moles,
        skinTone,
        predictiveFactorsHyperpigmentation
      } = this.selectFieldsBinding;

      return [
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.OXIDATIVE_STRESS}`),
          propertyName: 'yellow',
          color: DYSFUNCTIONS_COLORS[ACTIVES.OXIDATIVE_STRESS],
          questions: [complexion, tiredness, sunDamage]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_APPEARANCE}`),
          propertyName: 'pink',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_APPEARANCE],
          questions: [wrinkles, fineLines, elasticitySagging, volume]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_REDNESS}`),
          propertyName: 'red',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_REDNESS],
          questions: [rednessPresent, couperosePresent, isRosacea, isSunburn, isContactDermatitis, isEczema, isPsoriasis, isInfections, isAcne, isAllergicReaction]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_DRYNESS}`),
          propertyName: 'blue',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_DRYNESS],
          questions: [observedDryness, observedDehydration, predictiveFactorsDryness, predictiveFactorsDehydration]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_OILINESS}`),
          propertyName: 'orange',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_OILINESS],
          questions: [oiliness, pores]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_TEXTURE}`),
          propertyName: 'grey',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_TEXTURE],
          questions: [roughBumpySkin, dullSkin, unevenSkinTexture, roughness, scarring]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.PIMPLES}`),
          propertyName: 'green',
          color: DYSFUNCTIONS_COLORS[ACTIVES.PIMPLES],
          questions: [comedones, pustules, papules, nodules, cysts]
        }),
        new PhotoAnalysisResultSection({
          name: this.$t(`dashboard.diagnostic.${ACTIVES.SKIN_PIGMENTATION}`),
          propertyName: 'brown',
          color: DYSFUNCTIONS_COLORS[ACTIVES.SKIN_PIGMENTATION],
          questions: [melasma, postInflammatoryHyperpigmentation, ageSunSpots, freckles, moles, skinTone, predictiveFactorsHyperpigmentation]
        })
      ];
    },
    onFieldChange(propertyName, option) {
      this.selectFieldsData[propertyName] = option;
    },
    updateScore(propertyName, value) {
      this.sectionsScores[propertyName] = value;
    },
    async onNextClick() {
      const selectFieldsValues = Object.entries(this.selectFieldsData).reduce(
        (values, [key, option]) => {
          let value = get(option, 'value', option);

          if (key === 'ageGroup' && !Number.isInteger(value)) {
            value = this.initialUpdatedResults.ageGroup;
          }

          return {
            ...values,
            [key]: value
          };
        },
        {}
      );

      await this.updatePhotoAnalysisResults({ ...selectFieldsValues, ...this.sectionsScores });

      handleRoutingInfo(CLARIFICATION_ROUNTING_INFO, this.$router, this.$modal);
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('photo-analysis-result-update-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '../../../../../../assets/css/common/perfect-scrollbar-styles';
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/css/common/tooltips.css';

@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/treatment/create-treatment-shared';

.result {
  &__content {
    height: 580px;

    display: flex;

    padding-top: 30px;
    padding-right:30px;

    background-color: $white;
    color: $text-color;
  }

  &__questions {
    width: 330px;
    padding-right: 20px;
    align-items: flex-start;
  }

  &__scores {
    width: 450px;
    position: relative;
  }

  &__section {
    width: 100%;

    display: flex;
    justify-content: center;

    padding-top: 20px;
    padding-bottom: 20px;

    &:not(:last-child) {
      border-bottom: 1px solid $dim-white;
    }
  }

  &__footer {
    height: 70px;

    background-color: $white;
    border-top: 1px solid $dim-white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

}

.header {
  &__step {
    color: $grey-color;
    font-weight: bold;
  }
}

.section {
  &__container {
    width: 410px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding: 0 60px 0 5px;
  }

  &__section-name {
    font-size: 15px;
    line-height: 17px;

    margin-right: 15px;

    color: $text-color;
    text-align: right;
  }

  &__score {
    display: flex;
    align-items: center;
  }
}

.field-container {
  /*width: 100%;*/
  display: flex;
  align-items: center;


  &__title {
    font-size: 13px;
    text-align: left;
    font-weight: 300;
    width: 130px;
    padding-left: 20px;
  }

  .select-container {
   margin-left: 2px;
  }

  &.personal-data-select {
    .select-container {
      padding-right: 2px;
      width:250px;
    }
  }
}

.zoomable-photo-desktop {
  margin: 15px 0 0 40px;
}

.question {
  &__label {
    font-size: 13px;
    text-align: left;
    font-weight: 300;
  }

  &__field {
    position: relative;
    &:first-child {
      margin-top: 15px;
    }

    .select-container {
      --vs-border-radius: 0.7em;
      width: 430px;
      margin-left: 0px;
    }
  }
}

@media (min-width: 768px) {
  .zoomable-photo-mobile {
    display: none;
  }

  .result {
    &__section {
      &:nth-child(3) {
        padding-top: 6px;
      }
    }
  }
}

@media (max-width: 767px) {
  .result {
    min-height: 100vh;

    &__modal-header {
      position: fixed;
      top: 0;

      z-index: 51;

      background-color: $background;
    }

    &__content {
      height: auto;

      flex-direction: column;
      flex: 1;

      padding-top: 70px;
    }

    &__questions {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      padding-right: 0;
    }

    &__scores {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0 0 60px 0;
    }

    &__footer {
      height: 60px;

      z-index: 50;

      border-top: 0;
      box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);
    }
  }

  .header {
    &__title,
    &__step {
      color: $main-title-color;
      font-weight: initial;
    }
  }

  .section {
    &__container {
      width: 100%;
    }

    &__score {
      justify-content: flex-end;

      margin-bottom: 0;
    }

    &__section-name {
      font-size: 13px;
      line-height: 15px;
    }

    &__questions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .select-container {
        margin-left: 12px;
      }
    }
  }

  .field-container {
    /*width: 100%;*/

    &__title {
      font-weight: normal;
    }

    .select-container {
      margin: 0 -13px 0 5px;
    }
  }

  .zoomable-photo-desktop {
    display: none;
  }

  .zoomable-photo-mobile {
    margin-bottom: 30px;
  }

  .question {
    &__label {
      font-size: 12px;
    }

    &__field {
      width: 100%;
    }
  }
}
}
</style>
